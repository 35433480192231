import * as React from "react"
import { ThemeProvider } from "@mui/material"
import { CssBaseline } from "@mui/material"

import TopBar from "../components/TopBar"
import Footer from "../components/Footer"
import lightTheme from "../themes/light"
import HomeTextContainer from "../components/HomeTextContainer"
import SmallHero from "../components/SmallHero"

import { graphql } from "gatsby"

const FibraPage = ({data}) => {
  return (
    <ThemeProvider theme={lightTheme}>
      <title>ITS - Chi siamo</title>
      <CssBaseline />
      <TopBar  />
      <SmallHero 
        title="Fibra Ottica"
        description="Delivery FTTH e FTTC."
        image="https://scontent.fpsa1-2.fna.fbcdn.net/v/t1.6435-9/136074321_107818701229806_8171823942085211958_n.jpg?_nc_cat=108&ccb=1-5&_nc_sid=730e14&_nc_ohc=eSY5PB7ngZMAX8kWyrZ&tn=2MVHObMWMXOdbZs6&_nc_ht=scontent.fpsa1-2.fna&oh=6d4f1729215bcaf8601c0b0f11c28ffd&oe=61A58E55"
        hideButton={true}
        />

        <HomeTextContainer 
        title="ITS Telecomunicazioni //" 
        subtitle={data.markdownRemark.frontmatter.title} 
        hideIcon={true}
        hideButton={true}
        description={ data.markdownRemark.html }
        noBorder/>

      <Footer />
    </ThemeProvider>
  )
}

export default FibraPage

export const contentQuery = graphql`
{
  markdownRemark(fileAbsolutePath: { regex: "/fibra.md/"}) {
    frontmatter {
			title
    }
    html
  }
}
`